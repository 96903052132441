<template>
  <div class="the-navbar__user-meta flex items-center" v-if="user.id > 0">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ user.nama }}</p>
      <small>{{ user.access }}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <img v-if="user.avatar" key="onlineImg" :src="getImage('/profil/'+user.avatar)" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
          @click="$router.push('/user/setting').catch(() => {})">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Setting</span>
          </li>

        <!--

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Inbox</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Tasks</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Chat</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="HeartIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Wish List</span>
          </li>

        -->

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      user : 'auth/user',
    })
  },
  methods: {
    ...mapActions({
      setAlert  : 'alert/set',
      setAuth   : 'auth/set',
    }),
    logout() {
      this.$vs.loading()
      this.axios.post('/logout', this.user, this.user)
      .then(() => {
        this.setAuth({}) // kosongkan auth ketika logout
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Selamat',
          color  : 'success',
          text  : 'Logout Sukses',
        })
        this.$router.push('/login').catch(() => {})
      })
      .catch((error) => {
        this.setAuth({}) 
        let {data} = error.response
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Error',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        this.$router.push('/login').catch(() => {})
      })
    },
  }
}
</script>
