
//import { mapActions, mapGetters } from 'vuex'
export default [
  {
    url: "/",
    name: "Home",
    slug: "home",
    icon: "HomeIcon",
  },
  {
    url: "/page2",
    name: "Page 2",
    slug: "page2",
    icon: "FileIcon",
  },
  {
    name : 'Dashboard', 
    slug : '/', 
  },
  {
    name : 'Config', 
    slug : '#', 
    submenu : [
    {
      name : 'Menu', 
      slug : 'menu', 
    },
    {
      name : 'Otoritas', 
      slug : 'otoritas', 
    },
    {
      name : 'Menu Otoritas', 
      slug : 'menu-otoritas', 
    },
    ],
  },
]
