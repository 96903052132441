<template>
  <div class="flex">
    <div class="search-full-container w-full h-full absolute left-0 top-0" :class="{'flex': showFullSearch}" v-show="showFullSearch">
      <vx-auto-suggest
        ref="navbarSearch"
        :autoFocus="showFullSearch"
        :data="searchs"
        search_key="title"
        background-overlay
        class="w-full"
        inputClassses="w-full vs-input-no-border vs-input-no-shdow-focus"
        icon="SearchIcon"
        placeholder="Explore Makassar Profil ..."
        @input="hnd_search_query_update"
        @selected="selected"
        @closeSearchbar="showFullSearch = false">

        <template v-slot:group="{ group_name }">
          <p class="font-semibold text-primary">{{ group_name | title }}</p>
        </template>

        <template v-slot:judul="{ suggestion }">
          <router-link :to="`/post/${suggestion.post_name}`">
            <div class="flex items-end leading-none py-1">
              <span class="mt-1">{{ suggestion.post_title }}</span>
            </div>
          </router-link>
        </template>

        <template v-slot:content="{ suggestion }">
          <router-link :to="`/post/${suggestion.post_name}`">
            <div class="flex items-end leading-none py-1">
              <span class="mt-1">{{ truncate(suggestion.post_content) }}</span>
            </div>
          </router-link>
        </template>

        <!-- No Items Slot -->
        <template v-slot:noResult="{ group_name }">
          <div class="flex items-center">
            <feather-icon icon="InfoIcon" svgClasses="h-5 w-5" class="mr-4" />
            <span>No results found.</span>
          </div>
        </template>

      </vx-auto-suggest>

      <div class="absolute right-0 h-full z-50">
        <feather-icon
          icon="XIcon"
          class="px-4 cursor-pointer h-full close-search-icon"
          @click="showFullSearch = false" />
      </div>
    </div>
    <!--
    <feather-icon icon="SearchIcon" @click="showFullSearch = true" class="cursor-pointer navbar-fuzzy-search mr-4" />Search Topic
    -->
    <vs-button color="primary" type="flat" icon-pack="feather" icon="icon-search" @click="showFullSearch = true" class="cursor-pointer navbar-fuzzy-search m-0">Search Keyword</vs-button>
  </div>
</template>

<script>
import VxAutoSuggest from '@/components/vx-auto-suggest/VxAutoSuggest.vue';
import searchModule from '@/stores/atribut/search.js'

export default {
  components: {
    VxAutoSuggest
  },
  data () {
    return {
      //navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      showFullSearch: false,
      truncateText: null
    }
  },
  computed: {
    searchs () {
      return this.$store.state.search.searchs
    },
  },
  created () {
    if (!searchModule.isRegistered) {
      this.$store.registerModule('search', searchModule)
      searchModule.isRegistered = true
    }
    this.$store.dispatch('search/fetchSearch').catch(error => { 
      this.$vs.notify({
        title: error.status,
        color  : 'danger',
      })
    })
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    truncate(content) {
      return content.substr(content.indexOf(this.truncateText), 120)
    },
    selected (item) {
      if (item.pages) this.$router.push(item.pages.url).catch(() => {})
      this.showFullSearch = false
    },
    hnd_search_query_update (query) {
      // Show overlay if any character is entered
      this.$store.commit('TOGGLE_CONTENT_OVERLAY', !!query)
      this.truncateText = query
    }
  }
}

</script>
